import { IonCol, IonList,IonItem,IonLabel, IonContent, IonGrid, IonHeader, IonIcon, IonPage, IonRow,IonRouterLink,IonRange, IonToolbar } from '@ionic/react';
import React, {FC, useContext, useEffect, useRef} from 'react';
import { playCircleOutline,star,chevronBack,play, pauseCircleOutline,personCircleOutline,shuffleOutline,menu,playSkipBack, playSkipForward, starOutline } from 'ionicons/icons';
import logo from '../../assets/logo.jpg';
import {AppContext} from '../appProvider';
import config from '../../config/config.json';
import HeaderWithContent from '../../components/headerWithContent';
import iconPrev from '../../assets/images/icon-prev.png';
import iconNext from '../../assets/images/icon-next.png';
import iconPlay from '../../assets/images/icon-play.png';
import favorisBtn from '../../assets/images/favoris-btn.png';
import favorisBtnChecked from '../../assets/images/favoris-btn-checked.png';
import shuffleIcon from '../../assets/images/shuffle-icon.png';
import img_fond_playlist from '../../assets/images/icon vierge.png';
import musiqueService from '../liste_musique/musiqueService';


interface FavoryType {
    id: number,
    musique_ed: number,
    client_id: number,
    craeted_dat: string,
    updated_at: string
}

interface SoundType {
    id: number,
    title: string,
    filename: string,
    created_at: string,
    updated_at: string,
    playlist_id: number,
    reading: number,
    duration: string,
    Favoris?: FavoryType[],
    is_favoris?: boolean
}

const Player: FC = () => {
    const { duration, 
            definir_duration, 
            temps_courant, 
            definir_temps_courant,
            musique_courant, 
            pause, 
            mettre_en_pause, 
            mettre_musique_precedent, 
            mettre_musique_suivant, 
            aleatoire, 
            toggle_aleatoire,
            playlist_courant,
            definir_musique_courant,
            si_authentique,
            meta_playlist_courant,
            moi,
            position,
            mis_a_jour_favoris, 
            definir_playlist_courant} = useContext(AppContext);
    const audioRef = useRef<HTMLAudioElement>(null);

    // useEffect(() => {
    //     if (musique_courant && audioRef && audioRef.current) {
    //         if (!pause) {
    //             audioRef.current?.play().then(reponse => {
    //             definir_duration(audioRef.current?.duration)
    //             });
    //         } else {
    //             audioRef.current?.pause();
    //         }

    //         audioRef.current.addEventListener("timeupdate", (e) => {
    //             definir_temps_courant(audioRef.current?.currentTime)
    //         })

    //         audioRef.current.addEventListener("ended",(e) => {
    //             mettre_musique_suivant()
    //         })

    //     }
    // },[musique_courant, pause, duration,audioRef]);

    const handleKnogStart = (e: any) => {
        mettre_en_pause()
    }

    const handleKnobEnd = (e: any) => {
        if (audioRef && audioRef.current) {
            audioRef.current.currentTime = e.detail.value
            definir_temps_courant(e.detail.value)
            mettre_en_pause()
        }
    }

    const pad = (num: number) => {
        return ("0"+num).slice(-2);
    }
    const hhmmss = (secs: number) => {
        secs = Math.ceil(secs);
        var minutes = Math.floor(secs / 60);
        secs = secs%60;
        minutes = minutes%60;
        return `${pad(minutes)}:${pad(secs)}`;
    }

    const modifier_favoris = (index:number,sound_id: number) => {
        musiqueService.modifier_favoris(moi.id, {sound_id, user_id:moi.id})
            .then(reponse => {
                let playlist_mis_a_jour = playlist_courant;
                playlist_mis_a_jour[index].is_favoris = !playlist_mis_a_jour[index].is_favoris;
                if (musique_courant.id == sound_id) {
                    definir_musique_courant({...musique_courant,is_favoris: !musique_courant.is_favoris}, position);
                }
                definir_playlist_courant(playlist_mis_a_jour);
                mis_a_jour_favoris([...reponse.data_all]);
            })
            .catch(erreur => {
                console.error(erreur);
            })
    }

    return (
                <IonContent>
            {/* <IonPage>
                <HeaderWithContent> */}
                <div className="py-10 px-5">
                    <div className='grid grid-cols-6 mb-5'>
                        <div className="grid place-content-center">
                            <IonRouterLink routerLink='/playlists'>
                                <IonIcon className="text-[#EA491D] text-[32px]" icon={chevronBack}/>
                            </IonRouterLink>
                        </div>
                        <div className="col-span-4">
                            <h1 className="text-center text-[#EA491D] text-3xl text-semibold">{meta_playlist_courant && meta_playlist_courant.name ? meta_playlist_courant.name : "Auccun playlist"}</h1>
                        </div>
                    </div>
                    <div className="flex shadow-md">
                        <div className="basis-8/12 px-5 text-left"><h2>Titre du fichier</h2></div>
                        <div className="basis-2/12 text-left"><h2>Durée</h2></div>
                        <div className="basis-2/12 text-left"><h3>Favoris</h3></div>
                    </div>
                    <IonList lines="full" className="pb-[180px]">
                        {
                            playlist_courant && playlist_courant.length ? 
                            
                            playlist_courant.map((musique: SoundType, index: number) => (
                                <IonItem >
                                    <div className='flex flex-row w-full space-x-3'>
                                        <IonRouterLink className='basis-8/12 flex items-center space-x-1 overflow-hidden' onClick={() => definir_musique_courant(musique, index)} routerLink="/player">    
                                            <div className="flex">

                                                <div className='shrink-0 grid mr-5 place-content-center w-8 h-8 rounded-full bg-[#EA491D]'>
                                                    <IonIcon color='light' icon={play}></IonIcon>
                                                </div>
                                                <div className='overflow-hidden'>
                                                    <IonLabel className="truncate">{musique.title}</IonLabel>
                                                </div>
                                            </div>
                                        </IonRouterLink>
                                        <div className='basis-2/12'>
                                            <p className="text-center">3:45</p>
                                        </div>
                                        <div className='basis-2/12'>
                                            {musique && musique?.is_favoris ?
                                                <div className="bg-white text-center rounded-full" onClick={() => modifier_favoris(index,musique.id)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={star}></IonIcon>
                                                </div>
                                            : 
                                                <div className="bg-white text-center rounded-full" onClick={() => modifier_favoris(index,musique.id)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={starOutline}></IonIcon>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </IonItem>
                            ))
                            : null
                        }
                    </IonList>
                </div>
                <div className='absolute w-full inset-x-0 bottom-0'>
                        <div className="border-hidden relative">
                            <div className="relative custom-shape-divider-top-1651085402 bg-[#EA491D]">
                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                                </svg>
                            </div>
                        </div>
                        <div className='bg-[#EA491D] border-hidden text-white'>
                            <ul className='ml-5 flex space-x-5'>
                                {si_authentique ?
                                    <li><IonRouterLink routerLink='/playlists' color='light'>Playlists</IonRouterLink></li>
                                 : 
                                    <li><IonRouterLink routerLink='/' color='light'>Démo</IonRouterLink></li>
                                 }
                            </ul>
                        </div>
                        
                        <div className="bg-[#EA491D]  border-hidden flex items-center">
                            <div className='relative mx-auto w-auto h-auto text-center py-5'>
                                <div className='grid place-content-center p-4 w-auto h-auto rounded-full border-4 border-white'>
                                    {pause ? 
                                    <IonIcon onClick={() => mettre_en_pause(!pause)}  color='light' style={{fontSize: "148px"}} icon={playCircleOutline}/>
                                    :
                                    <IonIcon onClick={() => mettre_en_pause(!pause)}  color='light' style={{fontSize: "148px"}} icon={pauseCircleOutline}/>
                                    }
                                </div>
                                <div className="flex content-center items-center justify- absolute top-[38%] -left-6">
                                    <IonRouterLink onClick={() => mettre_musique_precedent()} color='light'>
                                        <div className='bg-white rounded-full grid place-content-center p-3'>
                                            <IonIcon style={{color: "#EA491D"}} size='large' icon={playSkipBack}></IonIcon>
                                        </div>
                                    </IonRouterLink>
                                </div>
                                <div className="flex content-center items-center justify- absolute top-[38%] -right-6">
                                    <IonRouterLink onClick={() => mettre_musique_suivant()} color='light'>
                                        <div className='bg-white rounded-full grid place-content-center p-3'>
                                            <IonIcon style={{color: "#EA491D"}} slot='icon-only' size='large' icon={playSkipForward}></IonIcon>
                                        </div>
                                    </IonRouterLink>
                                </div>
                            </div>
                        </div>

                        <div className='bg-[#EA491D] border-hidden py-2'>
                            <div className='mx-5 relative ion-no-border'>
                                <IonRange color='light' min={0} max={duration} value={temps_courant} onTimeUpdate={(e) => console.log(e)} onIonKnobMoveStart={(e) => handleKnogStart(e)}  onIonKnobMoveEnd={(e) => handleKnobEnd(e)}></IonRange>
                                <span className='absolute top-10 left-3 text-white'>{hhmmss(temps_courant)}</span>
                                <span className='absolute top-10 right-3 text-white'>{hhmmss(duration - temps_courant)}</span>
                            </div>
                        </div>

                        <div hidden>
                            {musique_courant && musique_courant.title ? 
                            <audio ref={audioRef} src={`${config.API_URL}/API/musique/play/${musique_courant.filename}`}/>
                            : null}
                        </div>

                        <div className='grid grid-cols-4 bg-[#EA491D] border-hidden gap-5 py-2 px-3'>
                            <div className=''>
                            {
                                    aleatoire ? 
                                    <div className="w-12 h-12 rounded-full p-2 border-2 border-white" onClick={() => toggle_aleatoire()}>
                                        <img className="w-full h-full" src={shuffleIcon} alt=""/>
                                    </div>
                                    :
                                    <div className="w-12 h-12 rounded-full p-2 border-2 border-transparent" onClick={() => toggle_aleatoire()}>
                                        <img className="w-full h-full" src={shuffleIcon} alt=""/>
                                    </div>
                                }
                            </div>
                            <div className='grid content-center text-center col-span-2 overflow-hidden'>
                                <h2 className='text-white text-lg truncate'> {musique_courant && musique_courant.title ? musique_courant.title : "pas de musique"} </h2>
                            </div>
                            <div className='grid justify-items-center'>
                                {/* <div className='bg-white rounded-full h-12 w-12 grid place-content-center text-center'>
                                    <IonIcon style={{fontSize: '32px',color: "#EA491D"}} icon={starOutline}/>
                                </div> */}
                                {musique_courant && musique_courant.is_favoris ? 
                                    <div className='bg-white p-2 rounded-full h-12 w-12' onClick={() => modifier_favoris(position, musique_courant.id)}>
                                        <img className="w-full h-full" src={favorisBtnChecked} alt=""/>
                                    </div>
                                :
                                    <div className='bg-white p-2 rounded-full h-12 w-12' onClick={() => modifier_favoris(position, musique_courant.id)}>
                                        <img className="w-full h-full" src={favorisBtn} alt=""/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                     {/* </HeaderWithContent> */}
                {/* </IonContent> */}
            {/* </IonPage> */}
            </IonContent>);
}

export default Player;