import { useContext, useState } from 'react';
import {IonPage,IonIcon, IonContent} from '@ionic/react';
import {lockClosedOutline} from 'ionicons/icons';
import HeaderWithContent from '../../components/headerWithContent';
import { Formik } from 'formik';
import * as Yup from 'yup';
import changementService from './changementService';
import { useHistory } from 'react-router';
import { AppContext } from '../appProvider';
const MotDePassse: React.FC = () => {
    const {definir_toast_message} = useContext(AppContext);
    const history = useHistory();

    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");

    const handleDidFocus = (field: string) => {
        setDidFocus(true);
        setCurrentField(field)
    }
    return (
            <IonContent>
                {/* <IonPage>
                    <HeaderWithContent> */}
                    <div>
                        <div className='flex'>
                            <div className="mx-auto">
                                <IonIcon className='text-center text-[128px] text-gray-500' icon={lockClosedOutline} />
                            </div>
                        </div>
                    <Formik
                        initialValues={{
                            email:"",
                            temp_code:"",
                            password: "",
                            confirmation:""
                        }}

                        validationSchema={Yup.object({
                            email: Yup.string().email("Doit etre un email valide").required("Email requis"),
                            temp_code: Yup.number().required("doit etre des chiffres"),
                            password: Yup.string().required("Mot de passe requis"),
                            confirmation: Yup.string().required("Confirmation requis").oneOf([Yup.ref('password'), null], 'Le mote de passe doit correspondre'),
                        })}

                        onSubmit={(values => {
                            changementService.changer_mot_de_passe(values) 
                                .then(reponse => {
                                    history.push("/connexion");
                                    definir_toast_message(reponse.status, reponse.message);
                                })
                                .catch(erreur => {
                                    if (erreur && erreur?.response?.data?.status) {
                                        definir_toast_message(erreur.response.data.status, erreur.response.data.message);
                                    } else {
                                        if (erreur.code == "ERR_NETWORK") {
                                            definir_toast_message(400, "Verifier votre connexion internet");
                                        } else {
                                            definir_toast_message(400, erreur.message);
                                        }
                                    }
                                })
                        })}
                    >{(formik) => (
                        <form onSubmit={formik.handleSubmit} className='p-10 space-y-5'>
                            <div className='space-y-3'>
                                <label className="block" htmlFor='email'>Email</label>
                                <input  type="email" 
                                        id="email"
                                        {...formik.getFieldProps("email")}
                                        onFocus={() => handleDidFocus("email")}
                                        className={`${!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                        {!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ?<span className="text-red-500 text-sm">{formik.errors.email}</span> : null }
                            </div>
                            <div className='space-y-3'>
                                <label className="block">Code</label>
                                <input  type="number"
                                        min="0"
                                        id="temp_code" 
                                        {...formik.getFieldProps("temp_code")}
                                        onFocus={() => handleDidFocus("etemp_codeail")}
                                        className={`${!!didFocus && currentField == "temp_code" && formik.errors.temp_code || formik.touched.temp_code && formik.errors.temp_code ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                        {!!didFocus && currentField == "temp_code" && formik.errors.temp_code || formik.touched.temp_code && formik.errors.temp_code ?<span className="text-red-500 text-sm">{formik.errors.temp_code}</span> : null }
                            </div>
                            <div className='space-y-3 w-full'>
                                        <label className="block" htmlFor='password'>Mot de passe</label>
                                        <input  type="password"
                                                id="password"
                                                {...formik.getFieldProps("password")} 
                                                onFocus={() => handleDidFocus("password")}
                                                className={`${!!didFocus && currentField == "password" && formik.errors.password || formik.touched.password && formik.errors.password ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "password" && formik.errors.password || formik.touched.password && formik.errors.password ?<span className="text-red-500 text-sm">{formik.errors.password}</span> : null }
                                    </div>
                                    <div className='space-y-3 w-full'>
                                    <label className="block">Confirmation</label>
                                    <input  type="password"
                                            id="confirmation"
                                            {...formik.getFieldProps("confirmation")}
                                            onFocus={() => handleDidFocus("confirmation")}
                                            className={`${!!didFocus && currentField == "confirmation" && formik.errors.confirmation || formik.touched.confirmation && formik.errors.confirmation ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "confirmation" && formik.errors.confirmation || formik.touched.confirmation && formik.errors.confirmation ?<span className="text-red-500 text-sm">{formik.errors.confirmation}</span> : null }
                                    </div>
                            <div className=''>
                                <button className="w-full block mx-auto rounded bg-[#EA491D] text-white px-4 py-2" type="submit">Changer mot de passe</button>
                            </div>
                        </form>
                    )}
                    </Formik>
                    </div>
                {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>)
}

export default MotDePassse;