import React , {FC,useContext,} from 'react';
import {IonPage, IonList, IonItem, IonTitle, IonHeader, IonContent,IonButton, IonLabel, IonToolbar, IonIcon, IonGrid, IonRow, IonCol, IonThumbnail, IonRange, IonRouterLink} from '@ionic/react';
import {menu, personCircleOutline,playSkipBackCircleOutline,playSkipBack,playSkipForward,shuffleOutline,starOutline, playSkipForwardCircleOutline, playBackCircleOutline, playForwardCircleOutline, playCircleOutline, person} from 'ionicons/icons';
import logo from '../../assets/logo.jpg';
import {Formik} from 'formik';
import * as Yup from 'yup';
import activationService from './activationService';
// import {storage} from '../appProvider';
import {AppContext} from '../appProvider';
import {useHistory, Redirect} from 'react-router-dom';
import HeaderWithContent from '../../components/headerWithContent';


const ActivationCompte: FC = () => {
    const {init_connexion, si_authentique, definir_toast_message} = useContext(AppContext);
    const history = useHistory();

    if (si_authentique) {
        return <Redirect to="/accueil"/>;
    }

    return (
        <IonContent>
            {/* <IonPage>
                <HeaderWithContent> */}
                    <Formik
                        initialValues={{
                            email:"",
                            mot_de_passe:"",
                            temp_code: ""
                        }}

                        validationSchema = {Yup.object({
                            email: Yup.string().email().required(),
                            mot_de_passe: Yup.string().required(),
                            temp_code: Yup.number().required()
                        })}
                        onSubmit={(values) => {
                            activationService.activation_compte(values)
                                .then(async (reponse) => {
                                    if (reponse.token) {
                                        await init_connexion(reponse.token);
                                        definir_toast_message(reponse.status, reponse.message);
                                        history.push("/accueil");
                                    }
                                })
                                .catch(erreur => {
                                    if (erreur && erreur?.response?.data?.status) {
                                        definir_toast_message(erreur.response.data.status, erreur.response.data.message);
                                    } else {
                                        if (erreur.code == "ERR_NETWORK") {
                                            definir_toast_message(400, "Verifier votre connexion internet");
                                        } else {
                                            definir_toast_message(400, erreur.message);
                                        }
                                    }
                                })
                        }}
                    >{(formik) => (
                        <div>
                            <div className='flex'>
                                <div className="mx-auto">
                                    <IonIcon className='text-center text-[128px] text-gray-500' icon={personCircleOutline}/>
                                </div>
                            </div>
                            <form className='p-10 space-y-5' onSubmit={formik.handleSubmit}>
                                <div className='space-y-3'>
                                    <label className="block">Email</label>
                                    <input  type="email" 
                                            id="email"
                                            {...formik.getFieldProps("email")}
                                            className="block w-full border border-gray-500 rounded px-3 py-1"/>
                                </div>
                                <div className='space-y-3'>
                                    <label className="block">Mot de passe</label>
                                    <input  type="password" 
                                            id="mot_de_passe"
                                            {...formik.getFieldProps("mot_de_passe")}
                                            className="block w-full border border-gray-500 rounded px-3 py-1"/>
                                </div>
                                <div className='space-y-3'>
                                    <label className="block">Code d'ativation</label>
                                    <input  type="number" 
                                            id="temp_code"
                                            {...formik.getFieldProps("temp_code")}
                                            className="block w-full border border-gray-500 rounded px-3 py-1"/>
                                </div>
                                <div className=''>
                                    <button className="w-full rounded block mx-auto bg-[#EA491D] text-white px-4 py-2" type="submit">Activer votre compte</button>
                                </div>
                            </form>
                        </div>
                    )}</Formik>

                {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>);
}

export default ActivationCompte;