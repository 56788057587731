import axios from 'axios';
import config from '../../config/config.json';


class ChangementMDPService {
    async changer_mot_de_passe(values: any) {
        const {data} = await axios.post(`${config.API_URL}/API/client/changement/mot-de-passe`, values);
        return data;
    }
}

export default new ChangementMDPService();