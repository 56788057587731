import axios from 'axios';
import config from '../../config/config.json';

class PlaylistService {
    async obtenir_tous_les_playlists() {
        const {data} = await axios.get(`${config.API_URL}/API/playlist/tout`);
        return data;
    }

    async obtenir_tous_les_demos() {
        const {data} = await axios.get(`${config.API_URL}/API/playlist/demo/tout`);
        return data;
    }

    async obtenir_un_playlist_par_id(playlist_id: number) {
        const {data} = await axios.get(`${config.API_URL}/API/playlist/${playlist_id}`);
        return data;
    }

    async obtenir_un_playlist_par_id_client(playlist_id: number, client_id: number) {
        const {data} = await axios.get(`${config.API_URL}/API/playlist/${playlist_id}/client/${client_id}`);
        return data;
    }
}

export default new PlaylistService()