import {IonPage,IonIcon, IonContent} from '@ionic/react';
import {lockClosedOutline} from 'ionicons/icons';
import HeaderWithContent from '../../components/headerWithContent';
import {Formik} from 'formik';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import recuperationService from './recuperationService';
import { AppContext } from '../appProvider';
import { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';

const Recuperation: React.FC = () => {
    const {definir_toast_message, si_authentique} = useContext(AppContext);
    const history = useHistory();

    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");

    if (si_authentique) {
        return <Redirect to="/playlists"/>;
      }

    const handleDidFocus = (field: string) => {
        setDidFocus(true);
        setCurrentField(field)
    }
    return (
            <IonContent>
            {/* <IonPage>
                <HeaderWithContent> */}
                    <div>
                        <div className='flex'>
                            <div className="mx-auto">
                                {/* <IonIcon className='text-center text-[128px] text-gray-500' icon={personCircleOutline}/> */}
                                <IonIcon className='text-center text-[128px] text-gray-500' icon={lockClosedOutline} />
                            </div>
                        </div>
                    <Formik
                        initialValues={{
                            email: ""
                        }}
                        validationSchema={Yup.object({
                            email: Yup.string().email("Doit etre un email valide").required("Email requis"),
                        })}
                        onSubmit={values => {
                            recuperationService.recuperer_mot_de_passe(values)
                                .then(reponse => {
                                    history.push("/changer-mot-de-passe");
                                    definir_toast_message(reponse.status, reponse.message);
                                })
                                .catch(erreur => {
                                    if (erreur && erreur?.response?.data?.status) {
                                        definir_toast_message(erreur.response.data.status, erreur.response.data.message);
                                    } else {
                                        if (erreur.code == "ERR_NETWORK") {
                                            definir_toast_message(400, "Verifier votre connexion internet");
                                        } else {
                                            definir_toast_message(400, erreur.message);
                                        }
                                    }
                                })
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit} className='p-10 space-y-5'>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor='email'>Email</label>
                                    <input  type="email" 
                                            id="email"
                                            {...formik.getFieldProps("email")}
                                            onFocus={() => handleDidFocus("email")}
                                            className={`${!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ?<span className="text-red-500 text-sm">{formik.errors.email}</span> : null }
                                </div>
                                <div>
                                </div>
                                <div className=''>
                                    <button className="block w-full rounded mx-auto bg-[#EA491D] text-white px-4 py-2" type="submit">Réinitialiser mon mot de passe</button>
                                </div>
                            </form>
                        )}
                    </Formik>
                    </div>
                {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>)
}

export default Recuperation;