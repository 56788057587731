import React from 'react';

function SuccesActivation(props) {
    return (<div className='px-5'>
                <div className='border-2 rounded border-green-500 p-5 space-y-3'>
                    <h2 className='text-2xl font-bold'>Bienvenue sur Radio Chanteclair</h2>
                    <p>Votre compte a été activé avec succès</p>
                </div>
            </div>);
}

export default SuccesActivation;