import axios from 'axios';
import config from '../../config/config.json';


class messageService {
    async tous_les_messages_par_id_client(senderId: number, toId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/message/tout/sender/${senderId}/to/${toId}`);
        return data;
    }

    async envoyer_message(donnees: object) {
        const {data} = await axios.post(`${config.API_URL}/API/message/ajout`, donnees);
        return data;
    }

    async modifier_programme(messageId: number, donnees: object) {
        const {data} = await axios.put(`${config.API_URL}/API/message/modification/${messageId}`, donnees);
        return data;
    }

    async supprimer_programme(messageId: number, donnees: object) {
        const {data} = await axios.delete(`${config.API_URL}/API/message/suppression/${messageId}`, donnees);
        return data;
    }

    async tous_les_messages_non_lu_par_client(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/message/messages-non-lu/tout/to/${clientId}`);
        return data;
    }

    async tous_les_messages_non_lu_par_client_group_par_sender(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/message/messages-non-lu/group/sender/tout/to/${clientId}`);
        return data;
    }

    async voir_message(values: any) {
        const {data} = await axios.post(`${config.API_URL}/API/message/voir`, values);
        return data
    }
}

export default new messageService();