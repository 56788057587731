import axios from 'axios';
import config from '../../config/config.json';

class MusiqueService {
    async getAllMusique() {
        const {data} = await axios.get(`${config.API_URL}/API/musique/all`);
        return data;
    }

    async modifier_favoris(clientId: number, donnees: object) {
        const {data} = await axios.put(`${config.API_URL}/API/favoris/update-or-create/${clientId}`, donnees, {
            timeout: 15000
        });
        return data;
    }

    async ajout_stat(user_id: number, sound_id: number) {
        if (sound_id != 9999999999){
            // const {data} = await axios.get(`https://www.radiochanteclair.com/read/sound/${sound_id}`);
            const {data} = await axios.get(`${config.API_URL}/API/stat/user/${user_id}/sound/${sound_id}`);
            return data;
        }
    }
}

export default new MusiqueService();