import axios from 'axios';
import config from '../../config/config.json';


class Inscription {
    async ajoutClient(values: object) {
        const {data} = await axios.post(`${config.API_URL}/API/client/create`,values);
        return data;
    }
}

export default new Inscription();