import React from 'react';

function ErreurActivation(props) {
    return (<div className='px-5'>
                <div className='border-2 rounded border-red-500 p-5 space-y-3'>
                    <h2 className='text-2xl font-bold'>Radio Chanteclair :-{"("} </h2>
                    <p>Malheureusement votre compte n'as pas été activé</p>
                </div>
            </div>);
}

export default ErreurActivation;