import {IonPage,IonRouterLink, IonIcon, IonContent, useIonViewDidEnter} from '@ionic/react';
import { useEffect, useState, useContext } from 'react';
import playlistService from './playlistService';
import { AppContext } from '../appProvider';
import HeaderWithContent from '../../components/headerWithContent';
import MiniPlayer from '../../components/minPlayer';
import img_fond_playlist from '../../assets/images/icon vierge.png';
import config from '../../config/config.json';
import favorisLogo from '../../assets/images/favoris.png';
import {play} from 'ionicons/icons';
import musiqueService from '../liste_musique/musiqueService';
import radio from '../../assets/images/radio.png';
import webradioService from './webradioService';
import { SoundType, WebradioType } from '../types';
import anniversaireService from '../liste_musique/anniversaireService';

type PlaylistPropsType = {
    clearIntervalStat: Function
}
interface Playlist {
    id: number,
    categorie: string,
    name: string,
    readings_number: number,
    type: string,
    cover: string,
    created_at: string,
    updated_at: string,
    link?: string,
    meta_link?:string,
    is_favoris: boolean
}

interface Webradio {
    id: number,
    categorie: string,
    name: string,
    readings_number: number,
    type: string,
    cover: string,
    link: string,
    is_favoris: boolean,
    created_at: string,
    updated_at: string
    cover_link: string
}



function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

var webRadioMetaPlaylist = {
        id: 1000000,
        name: "Web Radio",
        readings_number: 1,
        type: "DEMO",
        cover: "",
        created_at: "",
        updated_at:""
    }


const Playlist: React.FC<PlaylistPropsType> = ({clearIntervalStat, ...props}) => {
    const { si_authentique,
            definir_meta_playlist_courant,
            moi,
            definir_playlist_courant,
            definir_musique_courant,
            parametre,
            lire_playlist_anniversaire,
            definir_playlist_precedent,
            playlist_courant,
            webradios, 
            setWebradios,
            set_default_sound_anniversaire,
            default_sound_anniversaire,
            playlists_anniv,
            set_playlists_anniv
        } = useContext(AppContext);

    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [all_webradios_is_fetched, set_all_weradios_is_fetched] = useState(false);

    useIonViewDidEnter(() => {
        console.log("ionViewDidEnter event fired");
    })


    useEffect(() => {
        if (si_authentique && parametre && moi && moi.id) {
            /**
             *  OBTENIR TOUS LES PLAYLISTS
             */
            // playlistService.obtenir_tous_les_playlists()
            //     .then(reponse => {
            //         setPlaylists(reponse.data);
            //     })
            //     .catch(error => {
            //         console.error(error)
            //     })


            webradioService.obtenir_tous_les_webradios(moi.id)
                .then((reponse) => {
                    setWebradios(reponse.data);
                    set_all_weradios_is_fetched(true);
                })
                .catch(error => {
                    // console.error(error)
                })

            playlistService.obtenir_un_playlist_par_id_client(parametre.playlist_anniversaire, moi.id)
                .then(reponse => {
                    set_playlists_anniv([reponse.data]);
                    // setPlaylists([reponse.data]);
                })
                .catch(error => {
                    // console.error(error)
                });

            anniversaireService.get_anniversaire_sound(moi.id)
                .then((response) => {
                    if (response && response.data && response.data.Sound) {
                        set_default_sound_anniversaire(response.data.Sound);
                    }
                    
                })
                .catch(error => {
                    // console.error(error)
                })
            
        } else {

            if (!all_webradios_is_fetched) {
                webradioService.obtenir_tous_les_demos()
                .then((reponse) => {
                    setWebradios(reponse.data);
                    
                })
                .catch(error => {
                    console.error(error)
                })
            }

            // playlistService.obtenir_tous_les_demos()
            //     .then(reponse => {
            //         setPlaylists(reponse.data);
            //     })
            //     .catch(error => {
            //         console.error(error)
            //     })
        }

    },[si_authentique, parametre, moi, all_webradios_is_fetched]);

    const slugify = (mot: string) => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
        const p = new RegExp(a.split('').join('|'), 'g')
    
        return mot.toString().toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '') // Replace & with 'and'
          // .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/[^\w\-]+/g, '') // Remove all non-word characters
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text
    }

    const play_playlist = (playlist: Playlist) => {
        // if (playlist.id != 1000000) {
        //     if (parametre.playlist_anniversaire == playlist.id) {
        //         lire_playlist_anniversaire(true);
        //     }
    
        //     definir_meta_playlist_courant(playlist);
            
        //     playlistService.obtenir_un_playlist_par_id_client(playlist.id, moi.id)
        //         .then(response => {
        //             let id_courant = getRandomInt(response.data.Sounds.length - 1)
        //             definir_playlist_courant(response.data.Sounds)
        //             if (playlist.id != parametre.playlist_anniversaire) {
        //                 definir_playlist_precedent(response.data.Sounds);
        //             }
        //             definir_musique_courant(response.data.Sounds[id_courant], id_courant);
        //             musiqueService.ajout_stat(moi.id, response.data.Sounds[id_courant].id);
        //         })
        //         .catch(error => {
        //             console.error(error)
        //         })
        // } else if (playlist.id == 1000000) {
        //     definir_playlist_precedent([
        //         {
        //             id: 9999999999,
        //             title: "Web Radio",
        //             filename: "web-radio",
        //             created_at: "",
        //             updated_at: "",
        //             playlist_id: 1000000,
        //             reading: 1,
        //             duration: "--"
        //         }
        //     ])
            

        //     if (parametre.playlist_anniversaire == playlist.id) {
        //         lire_playlist_anniversaire(true);
        //     }

        //     definir_playlist_courant([
        //         {
        //             id: 9999999999,
        //             title: "Web Radio",
        //             filename: "web-radio",
        //             created_at: "",
        //             updated_at: "",
        //             playlist_id: 1000000,
        //             reading: 1,
        //             duration: "--"
        //         }
        //     ]);
        //     definir_musique_courant({
        //         id: 9999999999,
        //         title: "Web Radio",
        //         filename: "web-radio",
        //         created_at: "",
        //         updated_at: "",
        //         playlist_id: 1000000,
        //         reading: 1,
        //         duration: "--"
        //     }, 0)
        // } 



        if (playlist.categorie == "PLAYLIST") {
                if (parametre.playlist_anniversaire == playlist.id) {
                    lire_playlist_anniversaire(true);
                }
        
                definir_meta_playlist_courant(playlist);

                if (default_sound_anniversaire) {
                    // definir_playlist_courant([default_sound_anniversaire]);
                    definir_musique_courant(default_sound_anniversaire, 0);
                } else {
                    playlistService.obtenir_un_playlist_par_id_client(playlist.id, moi.id)
                        .then(response => {
                            let id_courant = getRandomInt(response.data.Sounds.length - 1)
                            definir_playlist_courant(response.data.Sounds)
                            if (playlist.id != parametre.playlist_anniversaire) {
                                definir_playlist_precedent(response.data.Sounds);
                            }
                            definir_musique_courant(response.data.Sounds[id_courant], id_courant);
                            // musiqueService.ajout_stat(moi.id, response.data.Sounds[id_courant].id);
                        })
                        .catch(error => {
                            console.error(error)
                        })
                }
        } 

        if (playlist.categorie == "WEBRADIO") {
            definir_playlist_precedent([
                {
                    id: playlist.id,
                    title: playlist.name,
                    filename: playlist.name,
                    created_at: playlist.created_at,
                    updated_at: playlist.updated_at,
                    categorie: playlist.categorie,
                    playlist_id: playlist.id,
                    link: playlist.link,
                    meta_link: playlist.meta_link,
                    is_favoris: playlist.is_favoris,
                    reading: 1,
                    duration: "--"
                }
            ])
            

            if (parametre && parametre.playlist_anniversaire == playlist.id) {
                lire_playlist_anniversaire(true);
            }

            // definir_playlist_courant([
            //     {
            //         id: playlist.id,
            //         title: playlist.name,
            //         filename: playlist.name,
            //         created_at: playlist.created_at,
            //         updated_at: playlist.updated_at,
            //         categorie: playlist.categorie,
            //         playlist_id: playlist.id,
            //         link: playlist.link,
            //         is_favoris: playlist.is_favoris,
            //         reading: 1,
            //         duration: "--"
            //     }
            // ]);

            definir_musique_courant({
                id: playlist.id,
                title: playlist.name,
                filename: playlist.name,
                created_at: playlist.created_at,
                updated_at: playlist.updated_at,
                categorie: playlist.categorie,
                playlist_id: playlist.id,
                link: playlist.link,
                meta_link: playlist.meta_link,
                is_favoris: playlist.is_favoris,
                reading: 1,
                duration: "--"
            }, 0)

            webradioService.mis_a_jour_stat({id:moi.id,webradio_id: playlist.id, meta_link: playlist.meta_link})
            .then(response => {

            }) 
            .catch(error => {

            })
        }   
    }

    return (
            // <IonPage>
            //     <HeaderWithContent>
            <IonContent>
                    <div className="my-10">
                        <h1 className="text-center text-[#EA491D] text-xl mobile360:text-2xl mobile412:text-3xl font-semibold">Ambiances Musicales</h1>
                    </div>
                    <div className='grid grid-cols-2 gap-5 pt-5 px-5 pb-[180px]'>
                        { webradios.length ?
                            webradios.map((webradio: WebradioType)  => (
                                (si_authentique && webradio.type == "DEMO") ? (
                                    null
                                ) : (

                                    <div key={webradio.id} className="">
                                            <div className="w-full">
                                                <div className="relative mx-auto h-24 w-24 mobile320:h-28 mobile320:w-28 mobile360:h-32 mobile360:w-32">
                                                    <IonRouterLink onClick={() => definir_meta_playlist_courant(webradio)}>
                                                        <img src={img_fond_playlist}/>
                                                        <div className="absolute inset-0 grid place-content-center">
                                                            <div className="h-24 w-24 rounded-full overflow-hidden">
                                                                <img className="h-full w-full" src={`${webradio.cover_link}`} alt=""/>
                                                                {/* <img className="h-full w-full" src={playlist && playlist.cover ? `${config.API_URL}/images/${playlist.cover}` : ""} alt=""/> */}
                                                            </div>
                                                        </div>
                                                    </IonRouterLink>
                                                    <div className='bg-[#EA491D] absolute grid place-content-center bottom-0 -right-6 w-12 h-12 rounded-full' onClick={() => {play_playlist(webradio)}}>
                                                        <IonIcon icon={play} className="text-white cursor-pointer" size='large'/>
                                                    </div>
                                                </div>
                                            </div>
                                            <IonRouterLink onClick={() => definir_meta_playlist_courant(webradio)}>
                                                <div className="px-3 py-5">
                                                    <h2 className="text-center font-semibold text-[#EA491D]">{webradio?.name}</h2>
                                                </div>
                                            </IonRouterLink>
                                    </div>
                                )
                            ))
                        : null}

                        
                        {/* { playlists.length ?  */}
                        { playlists_anniv.length ? 
                            <> 
                            
                            {
                            // playlists.map(playlist => (
                                playlists_anniv.map((playlist: any) => (
                                <div key={playlist.id} className="">
                                        <div className=" w-full">
                                            <div className="relative mx-auto h-24 w-24 mobile320:h-28 mobile320:w-28 mobile360:h-32 mobile360:w-32">
                                                <IonRouterLink onClick={() => definir_meta_playlist_courant(playlist)} routerLink='/musiques'>
                                                    <img src={img_fond_playlist}/>
                                                    <div className="absolute inset-0 grid place-content-center">
                                                        <div className="h-24 w-24">
                                                            <img className="h-full w-full" src={playlist && playlist.cover && playlist.name ? `https://www.radiochanteclair.com/public/uploads/sounds/${slugify(playlist.name)}/${playlist.cover}?r=62a72dda7b0bf` : ""} alt=""/>
                                                            {/* <img className="h-full w-full" src={playlist && playlist.cover ? `${config.API_URL}/images/${playlist.cover}` : ""} alt=""/> */}
                                                        </div>
                                                    </div>
                                                </IonRouterLink>
                                                <div className='bg-[#EA491D] absolute grid place-content-center bottom-0 -right-6 w-12 h-12 rounded-full' onClick={() => play_playlist(playlist)}>
                                                    <IonIcon icon={play} className="text-white cursor-pointer" size='large'/>
                                                </div>
                                            </div>
                                        </div>
                                    
                                    <IonRouterLink onClick={() => definir_meta_playlist_courant(playlist)} routerLink='/musiques'>
                                        <div className="px-3 py-5">
                                            <h2 className="text-center font-semibold text-[#EA491D]">{playlist?.name}</h2>
                                        </div>
                                    </IonRouterLink>

                                </div>
                            ))}


                                {/* {si_authentique ? 
                                    <div className="">
                                        <IonRouterLink routerLink='/favoris'>
                                            <div className=" w-full">
                                                <div className="relative mx-auto h-24 w-24 mobile320:h-28 mobile320:w-28 mobile360:h-32 mobile360:w-32">
                                                    <img src={img_fond_playlist}/>
                                                    <div className="absolute inset-0 grid place-content-center">
                                                        <div className="h-16 w-16">
                                                            <img className="h-full w-full" src={favorisLogo} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-3 py-5">
                                                <h2 className="text-center font-semibold text-[#EA491D]">Mes favoris</h2>
                                            </div>
                                        </IonRouterLink>
                                    </div>
                                : null} */}

                            </>
                        : 
                            // <div className="col-span-2">
                            //     <div className=" w-full">
                            //         <div className="mx-auto h-32 w-32 bg-gray-600 rounded-full"></div>
                            //     </div>
                            //     <div className="px-3 py-5">
                            //         <h2 className="text-center font-semibold text-[#EA491D]">Pas de playlist pour le moment</h2>
                            //     </div>
                            // </div>
                            null
                        } 


                    </div>
                    <div className='w-full h-[220px]'></div>
                    <MiniPlayer/>
                    </IonContent>)
            //     </HeaderWithContent>
            // </IonPage>)
}

export default Playlist;
