import {IonPage, IonHeader, IonContent, IonToolbar,IonRouterLink, IonButton, IonList, IonItem, IonLabel, IonTitle, IonIcon, IonListHeader, IonItemGroup, IonItemDivider, IonGrid, IonCol, IonRow, IonThumbnail} from '@ionic/react';
import {play,chevronBack, addOutline,pauseCircleOutline,star, menu, personCircleOutline, starOutline, playSkipBack, playSkipForward, playCircleOutline, shuffleOutline} from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assets/logo.jpg';
import {AppContext} from '../appProvider';
import favorisService from './favorisService';
import musiqueService from '../liste_musique/musiqueService';
import HeaderWithContent from '../../components/headerWithContent';
import MiniPlayer from '../../components/minPlayer';
import webradioService from '../playlist/webradioService';


interface MusiqueType {
    id: number,
    title: string,
    duration: string,
    filename: string,
    created_at: string,
    updated_at: string,
    playlist_id: number,
    categorie: string,
}

interface WebradioType{
    id: number,
    categorie: string,
    name: string,
    duration: string,
    filename: string,
    created_at: string,
    updated_at: string,
    link: string,
    is_favoris: boolean
}

interface FavorisWebradioType {
    id: number,
    user_id: number,
    webradio_id: number,
    Webradio?: WebradioType,
    created_at: string,
    updated_at: string
}

interface FavorisType {
    id: number,
    user_id: number,
    sound_id: number,
    Sound?: MusiqueType,
    created_at: string,
    updated_at: string
}


const Favoris: React.FC = () => {
    const { moi,
            definir_musique_courant,
            definir_playlist_courant,
            mis_a_jour_favoris,
            mis_a_jour_favoris_webradios,
            musique_courant,
            favoris,
            favoris_webradios,
            webradios,
            setWebradios,
            position} = useContext(AppContext);

    const [favoris_playlist, set_favoris_playlist] =  useState([]);
    const [favoris_webradios_mapped, set_favoris_webradios_mapped] = useState([]);
    useEffect(() => {
        if (moi && moi.id) {
            favorisService.recuperer_favoris(moi.id)
                .then(reponse => {
                    mis_a_jour_favoris(reponse.data);
                    let playlist_favoris_courant = reponse.data.map((item: FavorisType) => {
                        return {...item.Sound, is_favoris: true}
                    })
                    set_favoris_playlist(playlist_favoris_courant)
                })
                .catch(error => {
                    console.error(error)
                })
            
            webradioService.recuperer_favoris_webradio(moi.id)
                .then(reponse => {
                    let all_favoris_webradios = reponse.data.map((favori_webradio: any) => {   

                            return ({
                                id: favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.id : "",
                                name: favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.name : "",
                                title: favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.name : "",
                                filename: favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.name : "",
                                created_at: favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.created_at : "",
                                updated_at: favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.updated_at : "",
                                categorie:favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.categorie : "",
                                playlist_id: favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.id : "",
                                link:favori_webradio & favori_webradio.Webradio ? favori_webradio.Webradio.link : "",
                                is_favoris: true,
                                reading: 1,
                                duration: "--"
                            })
                    })

                    mis_a_jour_favoris_webradios(reponse.data);
                    set_favoris_webradios_mapped(all_favoris_webradios);

                })
                .catch(error => {
                    console.error(error)
                })
        }
    },[moi])

    const modifier_favoris = (index: number, sound_id: number) => {
        if (sound_id) {
            musiqueService.modifier_favoris(moi.id, {sound_id, user_id:moi.id})
                .then(reponse => {
                    let favoris_mis_a_jour = favoris;
                    favoris_mis_a_jour.splice(index,1);
                    if (musique_courant && musique_courant.id == sound_id) {
                        definir_musique_courant({...musique_courant,is_favoris: !musique_courant.is_favoris}, position);
                    }
                    mis_a_jour_favoris([...reponse.data_all]);
                })
                .catch(erreur => {
                    console.error(erreur);
                })
        }
    }

    const modifier_favoris_webradio = (index: number, webradio: WebradioType | null) => {
        if (webradio) {
            webradioService.modifier_favoris_webradio(moi.id, {webradio_id: webradio.id, user_id: moi.id})
                .then(reponse => {
                    let favoris_mis_a_jour = favoris_webradios;
                    favoris_mis_a_jour.splice(index,1);
                    if (musique_courant && musique_courant.id == webradio.id) {
                        definir_musique_courant({...musique_courant,is_favoris: !musique_courant.is_favoris}, position);
                    }

                    let webradio_courant_index = webradios.findIndex((current_webradio: any) => (current_webradio.id == webradio.id));
                    if (webradio_courant_index >= 0) {
                        webradios[webradio_courant_index].is_favoris = false;
                        setWebradios([...webradios]);
                    }

                    mis_a_jour_favoris_webradios([...reponse.data_all]);
                })
                .catch(erreur => {
                    console.error(erreur);
                })
        }
    }

    const definir_musique_courant_webradio = (favori: FavorisWebradioType, index: number) => {
        if (favori && favori.Webradio) {
            definir_musique_courant(
                {
                    id: favori.Webradio.id,
                    title: favori.Webradio.name,
                    filename: favori.Webradio.name,
                    created_at: favori.Webradio.created_at,
                    updated_at: favori.Webradio.updated_at,
                    categorie: favori.Webradio.categorie,
                    playlist_id: favori.Webradio.id,
                    link: favori.Webradio.link,
                    is_favoris: true,
                    reading: 1,
                    duration: "--"
                }
                , index);
        }
    }   

    return (
            <IonContent>
            {/* <IonPage>
                <HeaderWithContent> */}
                    <div className="pt-36 pb-10">
                        <div className='grid grid-cols-6'>
                            <div className="grid place-content-center">
                                <IonRouterLink routerLink='/playlists'>
                                    <IonIcon className="text-[#EA491D] text-[32px]" icon={chevronBack}/>
                                </IonRouterLink>
                            </div>
                            <div className="col-span-4">
                                <h1 className="text-center text-[#EA491D] text-3xl text-semibold">Favoris</h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex shadow-md">
                        <div className="basis-8/12 px-5 text-left"><h2>Titre du fichier</h2></div>
                        <div className="basis-2/12 text-left"><h2>Durée</h2></div>
                        <div className="basis-2/12 text-left"><h3>Favoris</h3></div>
                    </div>
                    <IonList lines="full" className="pb-[180px]">
                    {
                            favoris_webradios && favoris_webradios.length ? 
                            <>{
                                favoris_webradios.map((favori: FavorisWebradioType, index: number) => (
                                    <IonItem >
                                        <div className='flex flex-row w-full space-x-3'>
                                            <IonRouterLink className='basis-8/12 flex items-center space-x-1 overflow-hidden' onClick={() => {definir_musique_courant_webradio(favori, index);definir_playlist_courant(favoris_webradios_mapped)}}>    
                                                <div className="flex">
    
                                                    <div className='shrink-0 grid mr-5 place-content-center w-8 h-8 rounded-full bg-[#EA491D]'>
                                                        <IonIcon color='light' icon={play}></IonIcon>
                                                    </div>
                                                    <div className='overflow-hidden'>
                                                        <IonLabel className="truncate">{favori && favori.Webradio && favori.Webradio.name ? favori.Webradio.name : ""}</IonLabel>
                                                    </div>
                                                </div>
                                            </IonRouterLink>
                                            <div className='basis-2/12'>
                                                <p className="text-center">{"--:--:--"}</p>
                                            </div>
                                            <div className='basis-2/12'>
                                                <div className="bg-white text-center rounded-full" onClick={() => modifier_favoris_webradio(index,favori && favori.Webradio ? favori.Webradio : null)}>
                                                {/* <div className="bg-white text-center rounded-full"> */}
                                                    <IonIcon style={{color: '#EA491D' }} icon={star}></IonIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </IonItem>
                                ))
                            }
                            </>
                            : null
                        }
                        {
                            favoris && favoris.length ? 
                            <>{
                                favoris.map((favori: FavorisType, index: number) => (
                                    <IonItem onClick={() => definir_musique_courant({...favori.Sound, is_favoris: true}, index)}>
                                        {/* <div className='flex flex-row w-full space-x-3'>
                                            <div className='basis-8/12 flex items-center space-x-1 overflow-hidden'>
                                                <div className='shrink-0 grid mr-5 place-content-center w-8 h-8 rounded-full bg-[#EA491D]'>
                                                    <IonIcon color='light' icon={play}></IonIcon>
                                                </div>
                                                <div className='overflow-hidden'>
                                                    <IonLabel className="truncate">{favori && favori.Sound ? favori.Sound.title : ""}</IonLabel>
                                                </div>
                                            </div>
                                            <div className='basis-2/12'>
                                                <p className="text-center">3:45</p>
                                            </div>
                                            <div className='basis-2/12'>
                                                <div className="bg-white text-center rounded-full" onClick={() => modifier_favoris(index, favori && favori.Sound ? favori.Sound.id : 0)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={star}></IonIcon>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className='flex flex-row w-full space-x-3'>
                                            <IonRouterLink className='basis-8/12 flex items-center space-x-1 overflow-hidden' onClick={() => {definir_musique_courant(favori, index);definir_playlist_courant(favoris_playlist)}}>    
                                                <div className="flex">
    
                                                    <div className='shrink-0 grid mr-5 place-content-center w-8 h-8 rounded-full bg-[#EA491D]'>
                                                        <IonIcon color='light' icon={play}></IonIcon>
                                                    </div>
                                                    <div className='overflow-hidden'>
                                                        <IonLabel className="truncate">{favori && favori.Sound ? favori.Sound.title : ""}</IonLabel>
                                                    </div>
                                                </div>
                                            </IonRouterLink>
                                            <div className='basis-2/12'>
                                                <p className="text-center">{favori && favori?.Sound && favori?.Sound?.duration ? favori.Sound.duration : "00:00:00"}</p>
                                            </div>
                                            <div className='basis-2/12'>
                                                <div className="bg-white text-center rounded-full" onClick={() => modifier_favoris(index,favori && favori.Sound && favori.Sound.id ? favori.Sound.id : 0)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={star}></IonIcon>
                                                </div>
                                            </div>
                                        </div>
                                    </IonItem>
                                ))
                            }
                                <IonItem>
                                    <div className='w-full h-[220px]'></div>
                                </IonItem>
                            </>
                            : null
                        }
                    </IonList>
                    <MiniPlayer/>
                    {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>)
}

export default Favoris;