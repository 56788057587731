import axios from 'axios';
import config from '../../config/config.json';


class conversation {
    async tous_les_conversations_par_id(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/message/conversation/sender/${clientId}`);
        return data;
    }

    async tous_les_utilisateurs(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/message/conversation/tout/${clientId}`);
        return data;
    }

    async tous_les_conversations_avec_message_non_lu(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/message/messages-non-lu/group/sender/tout/to/${clientId}`);
        return data;
    }

    async conversation_avec_message_non_lu_admin(clientId: number, adminId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/message/messages-non-lu/admin/${adminId}/to/${clientId}`);
        return data;
    }

    async voir_tous_les_message_non_lu(values: any) {
        const {data} = await axios.post(`${config.API_URL}/API/message/voir-messages`, values);
        return data;
    }
}

export default new conversation();