import axios from 'axios';
import config from '../../config/config.json';

class ActivationService {
    async activation_compte(values: object) {
        const {data} = await axios.post(`${config.API_URL}/API/client/activation-compte`, values);
        return data;
    }
}

export default new ActivationService();