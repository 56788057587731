import axios from 'axios';
import config from '../../config/config.json';


class FavorisService {
    async recuperer_favoris(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/favoris/tout/${clientId}`);
        return data;
    }
}

export default new FavorisService();