import { FC, useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import {AppContext} from './appProvider';

interface PropsType {
    children: any;
    path?: string;
    exact?: boolean;
    rest?: any;
}

const HiddenRoute: FC<PropsType> = ({children,...rest}) => {
    const {si_authentique} = useContext(AppContext);
    return (<Route
                {...rest}
                render={({location}) => (si_authentique ? <Redirect to="/playlists" /> : children)}
            />)
}

export default HiddenRoute;