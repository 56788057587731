import axios from 'axios';
import config from '../../config/config.json';

class WebradioService {
    async obtenir_tous_les_webradios(user_id: number) {
        const {data} = await axios.get(`${config.API_URL}/API/webradio/tout/${user_id}`);
        return data;
    }

    async obtenir_tous_les_demos() {
        const {data} = await axios.get(`${config.API_URL}/API/webradio/demo/tout`);
        return data;
    }

    async obtenir_un_webradio_par_id(webradio_id: number) {
        const {data} = await axios.get(`${config.API_URL}/API/webradio/${webradio_id}`);
        return data;
    }

    async obtenir_un_webradio_par_id_client(webradio_id: number, client_id: number) {
        const {data} = await axios.get(`${config.API_URL}/API/webradio/${webradio_id}/client/${client_id}`);
        return data;
    }

    async modifier_favoris_webradio(clientId: number, donnees: object) {
        const {data} = await axios.put(`${config.API_URL}/API/favoris-webradios/update-or-create/${clientId}`, donnees);
        return data;
    }

    async recuperer_favoris_webradio(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/favoris-webradios/tout/${clientId}`);
        return data;
    }

    async mis_a_jour_stat(values: any) {
        const {data} = await axios.post(`${config.API_URL}/API/stat/update`,values);
        return data;
    }
}

export default new WebradioService()