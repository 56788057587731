import {IonPage,IonRouterLink, IonList, IonItem, IonLabel,IonIcon, IonContent} from '@ionic/react';
import {play,chevronBack,star, starOutline, notifications, notificationsOffOutline} from 'ionicons/icons';
import React, { useContext, useEffect} from 'react';
import {AppContext} from '../appProvider';
import playlistService from '../playlist/playlistService';
import musiqueService from './musiqueService';
import HeaderWithContent from '../../components/headerWithContent';
import MiniPlayer from '../../components/minPlayer';
import anniversaireService from './anniversaireService';


interface FavoryType {
    id: number,
    musique_ed: number,
    client_id: number,
    craeted_dat: string,
    updated_at: string
}

interface SoundType {
    id: number,
    title: string,
    categorie: string,
    filename: string,
    created_at: string,
    updated_at: string,
    playlist_id: number,
    reading: number,
    duration: string,
    Favoris?: FavoryType[],
    is_favoris?: boolean,
    is_anniversaire?: boolean
}


const ListeMusique: React.FC = () => {
    const { moi,
            favoris, 
            meta_playlist_courant, 
            definir_musique_courant,
            musique_courant, 
            position, 
            definir_playlist_courant,
            playlist_courant, 
            mis_a_jour_favoris,
            si_authentique,
            parametre,
            definir_playlist_precedent,
            lire_playlist_anniversaire,
            set_default_sound_anniversaire } = useContext(AppContext);

    useEffect(() => {
        if (si_authentique) {
            if (meta_playlist_courant && moi && moi.id) {
                playlistService.obtenir_un_playlist_par_id_client(meta_playlist_courant.id, moi.id)
                    .then(response => {
                        definir_playlist_courant(response.data.Sounds)
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        } else {
            if (meta_playlist_courant) {
                playlistService.obtenir_un_playlist_par_id(meta_playlist_courant.id)
                    .then(response => {
                        definir_playlist_courant(response.data.Sounds)
                    })
                    .catch(error => {
                        console.error(error)
                    })
            }
        }
    },[meta_playlist_courant, favoris, moi, si_authentique])

    const modifier_favoris = (index:number,sound_id: number) => {
        musiqueService.modifier_favoris(moi.id, {sound_id, user_id:moi.id})
            .then(reponse => {
                let playlist_mis_a_jour = playlist_courant;
                playlist_mis_a_jour[index].is_favoris = !playlist_mis_a_jour[index].is_favoris;
                if (musique_courant && musique_courant.id == sound_id) {
                    definir_musique_courant({...musique_courant,is_favoris: !musique_courant.is_favoris}, position);
                }
                definir_playlist_courant(playlist_mis_a_jour);
                mis_a_jour_favoris([...reponse.data_all]);
            })
            .catch(erreur => {
                console.error(erreur);
            })
    }

    const modifier_anniv = (index: number, sound_id: number) => {
        anniversaireService.modifier_anniversaire(moi.id, {sound_id, user_id: moi.id})
            .then(response => {
                if (response && response.sound) {
                    set_default_sound_anniversaire(response.sound);
                } else {
                    set_default_sound_anniversaire(null);
                }

                let playlist_mis_a_jour = playlist_courant.map((item: any, index_item: number) => {
                    if (index_item == index) {
                        item.is_anniversaire = !item.is_anniversaire;
                    } else {
                        item.is_anniversaire = false;
                    }
                    return item;
                });
                
                definir_playlist_courant(playlist_mis_a_jour);
            })
            .catch(erreur => {
                // console.error(erreur);
            })
    }

    const play_musique = (musique: SoundType, index: number) => {
        if (playlist_courant.length && playlist_courant[0].playlist_id != parametre.playlist_anniversaire) {
            definir_playlist_precedent(playlist_courant);
        }  
        if (parametre.playlist_anniversaire == meta_playlist_courant.id) {
            lire_playlist_anniversaire(true);
        } 

        definir_musique_courant(musique, index);
        // musiqueService.ajout_stat(moi.id, musique.id);
    }

    return (
            <IonContent>
            {/* <IonPage>
                <HeaderWithContent> */}
                    <div className="pt-28 pb-10">
                        <div className='grid grid-cols-6'>
                            <div className="grid place-content-center">
                                <IonRouterLink routerLink='/playlists'>
                                    <IonIcon className="text-[#EA491D] text-[32px]" icon={chevronBack}/>
                                </IonRouterLink>
                            </div>
                            <div className="col-span-4">
                                <h1 className="text-center text-[#EA491D] text-3xl text-semibold">{meta_playlist_courant && meta_playlist_courant.name ? meta_playlist_courant.name : "Auccun playlist"}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex shadow-md">
                        <div className="basis-6/12 px-5 text-left"><h2>Titre du fichier</h2></div>
                        <div className="basis-2/12 text-left"><h2>Anniv</h2></div>
                        <div className="basis-2/12 text-left"><h2>Durée</h2></div>
                        <div className="basis-2/12 text-left"><h3>Favoris</h3></div>
                    </div>
                    <IonList style={{zIndex:10}} lines="full">
                        {
                            playlist_courant && playlist_courant.length ? 
                            <>{
                            playlist_courant.map((musique: SoundType, index: number) => (
                                <IonItem key={musique.id}>
                                    <div className='flex flex-row w-full space-x-3'>
                                        <IonRouterLink className='basis-6/12 flex items-center space-x-1 overflow-hidden' onClick={() => play_musique(musique, index)}>    
                                            <div className="flex cursor-pointer">

                                                <div className='shrink-0 grid mr-5 place-content-center w-8 h-8 rounded-full bg-[#EA491D]'>
                                                    <IonIcon color='light' icon={play}></IonIcon>
                                                </div>
                                                <div className='overflow-hidden'>
                                                    <IonLabel className="truncate">{musique.title}</IonLabel>
                                                </div>
                                            </div>
                                        </IonRouterLink>
                                        <div className='basis-2/12'>
                                            {musique && musique?.is_anniversaire ?
                                                <div className="bg-white text-center rounded-full cursor-pointer" onClick={() => modifier_anniv(index,musique.id)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={notifications}></IonIcon>
                                                </div>
                                            : 
                                                <div className="bg-white text-center rounded-full  cursor-pointer" onClick={() => modifier_anniv(index,musique.id)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={notificationsOffOutline}></IonIcon>
                                                </div>
                                            }
                                        </div>
                                        <div className='basis-2/12'>
                                            <p className="text-center">{musique && musique?.duration ? musique.duration : "00:00:00"}</p>
                                        </div>
                                        <div className='basis-2/12'>
                                            {musique && musique?.is_favoris ?
                                                <div className="bg-white text-center rounded-full cursor-pointer" onClick={() => modifier_favoris(index,musique.id)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={star}></IonIcon>
                                                </div>
                                            : 
                                                <div className="bg-white text-center rounded-full cursor-pointer" onClick={() => modifier_favoris(index,musique.id)}>
                                                    <IonIcon style={{color: '#EA491D' }} icon={starOutline}></IonIcon>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </IonItem>
                            ))
                            }
                                <IonItem>
                                    <div className='w-full h-[220px]'></div>
                                </IonItem>
                            </>
                            : null
                        }
                    </IonList>
                    <MiniPlayer/>
                    {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>);
}

export default ListeMusique;