import React , {FC,useContext, useState} from 'react';
import {IonPage, IonIcon, IonRouterLink, IonContent} from '@ionic/react';
import {personCircleOutline} from 'ionicons/icons';
import {Formik} from 'formik';
import * as Yup from 'yup';
import connexionService from './connexionService';
import {AppContext} from '../appProvider';
import {useHistory, useLocation, Redirect} from 'react-router-dom';
import HeaderWithContent from '../../components/headerWithContent';
import {LocalNotifications} from '@capacitor/local-notifications';
import config from '../../config/config.json';


const Connexion: FC = () => {
    const {init_connexion, si_authentique, definir_toast_message} = useContext(AppContext);
    const history = useHistory();
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");

    if (si_authentique) {
        return <Redirect to="/playlists"/>;
    }


    const handleDidFocus = (field: string) => {
        setDidFocus(true);
        setCurrentField(field)
    }
    return ( <IonContent>
             {/* <IonPage>
                 <HeaderWithContent> */}
                    <Formik
                        initialValues={{
                            email:"",
                            mot_de_passe:""
                        }}

                        validationSchema = {Yup.object({
                            email: Yup.string().email("Doit etre un email valide").required("Email requis"),
                            mot_de_passe: Yup.string().required("Mot de passe requis")
                        })}
                        onSubmit={(values) => {
                            connexionService.connexionClient(values)
                                .then(async (reponse) => {
                                    LocalNotifications.schedule({
                                        notifications: [{
                                            id:1,
                                            title: `Radio Chanteclair`,
                                            body: "Bienvenue sur Radio Chanteclair",
                                            sound:""
                                        }]
                                    })
                                    if (reponse.token) {
                                        await init_connexion(reponse.token);
                                        history.push("/playlists");
                                    }
                                })
                                .catch(error => {
                                    if (error && error?.response?.data?.status) {
                                        definir_toast_message(error.response.data.status, error.response.data.message);
                                    } else {
                                        if (error.code == "ERR_NETWORK") {
                                            definir_toast_message(400, "Verifier votre connexion internet");
                                            LocalNotifications.schedule({
                                                notifications: [{
                                                    id:1,
                                                    title: `Radio Chanteclair`,
                                                    body: "Verifier votre connexion internet",
                                                    sound:""
                                                }]
                                            })
                                        } else {
                                            definir_toast_message(400, error.message);
                                        }
                                    }
                                })
                        }}
                    >{(formik) => (
                        <div>
                            <div className='flex'>
                                <div className="mx-auto">
                                    <IonIcon className='text-center text-[128px] text-gray-500' icon={personCircleOutline}/>
                                </div>
                            </div>
                            <form className='p-10 space-y-5' onSubmit={formik.handleSubmit}>
                                <div className='space-y-3'>
                                    <label className="block" htmlFor='email'>Email</label>
                                    <input  type="email" 
                                            id="email"
                                            {...formik.getFieldProps("email")}
                                            onFocus={() => handleDidFocus("email")}
                                            className={`${!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "email" && formik.errors.email || formik.touched.email && formik.errors.email ?<span className="text-red-500 text-sm">{formik.errors.email}</span> : null }
                                </div>
                                <div className='space-y-3 w-full'>
                                        <label className="block" htmlFor='password'>Mot de passe</label>
                                        <input  type="password"
                                                id="mot_de_passe"
                                                {...formik.getFieldProps("mot_de_passe")} 
                                                onFocus={() => handleDidFocus("mot_de_passe")}
                                                className={`${!!didFocus && currentField == "mot_de_passe" && formik.errors.mot_de_passe || formik.touched.mot_de_passe && formik.errors.mot_de_passe ? "border border-red-500 focus:ring-2 focus:ring-red-500" : "border border-gray-500 focus:ring-2 focus:ring-blue-500"} w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`} />
                                            {!!didFocus && currentField == "mot_de_passe" && formik.errors.mot_de_passe || formik.touched.mot_de_passe && formik.errors.mot_de_passe ?<span className="text-red-500 text-sm">{formik.errors.mot_de_passe}</span> : null }
                                    </div>
                                <div>
                                    <IonRouterLink routerLink='/recuperation-mot-de-passe'>
                                        Mot de passe oublié ?
                                    </IonRouterLink>
                                </div>
                                <div className=''>
                                    <button className="block w-full rounded mx-auto bg-[#EA491D] text-white px-4 py-2" type="submit">Connexion</button>
                                </div>
                            </form>
                        </div>
                    )}</Formik>
                {/* </HeaderWithContent>
            </IonPage> */}
            </IonContent>)
}

export default Connexion;