import React ,{useContext, useEffect, useRef} from 'react'; 
import { Redirect, Route, useHistory,Link, useLocation } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  IonHeader,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonRouterLink,
  IonMenu,
  IonTitle,
  IonList,
  IonItem,
  IonContent,
  IonToast,
  IonThumbnail,
  IonPage,
  useIonViewDidEnter
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse,ellipsisVerticalOutline, starOutline,alarmOutline,personOutline, chatbubbleEllipsesOutline, menu,square, triangle,personCircleOutline, listCircleOutline, homeOutline, musicalNotesOutline } from 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import logo from './assets/logo.jpg';

/* Theme variables */
import './theme/variables.css';
import Accueil from './pages/accueil/accueil';
import Musics from './pages/liste_musique/liste_musique';
import Playlist from './pages/playlist/playlist';
import Player from './pages/player/player';
import Inscription from './pages/inscription/inscription';
import Connexion from './pages/connexion/connexion';
import Recovery from './pages/recuperation/recuperation';
import PrivateRoute from './pages/privateRoute';
import HiddenRoute from './pages/hiddenRoute';
import { AppContext } from './pages/appProvider';
import Message from './pages/message/message';
import Coordonnees from './pages/coordonnees/coordonnees';
import Favoris from './pages/favoris/favoris';
import Programmation from './pages/programmation/programmation';
import config from './config/config.json';
import { io } from 'socket.io-client';
import MotDePassse from './pages/mot_de_passe/mot_de_passe';
import ActivationCompte from './pages/activation/activation';
import programmationService from './pages/programmation/programmationService';
import moment from 'moment';
import playlistService from './pages/playlist/playlistService';
import Conversation from './pages/conversation/conversation';
import messageService from './pages/message/messageService';
import conversationService from './pages/conversation/conversationService';

import {App as CapacitorApp} from '@capacitor/app';
import { LocalNotifications } from '@capacitor/local-notifications';
import axios from 'axios';
import HeaderWithContent from './components/headerWithContent';
import webradioService from './pages/playlist/webradioService';
import SuccesActivation from './pages/succes_activation/succes_activation';
import ErreurActivation from './pages/erreur_activation/erreurActivation';


setupIonicReact();

interface MenuCustomEvent<T = any> extends CustomEvent {
  detail: T;
  target: HTMLIonMenuElement;
}

const App: React.FC = () => {
  const { si_authentique,
          moi,
          parametre,
          mettre_en_pause,
          definir_pause,
          pause,definir_duration, 
          definir_temps_courant,
          mettre_musique_suivant,
          duration, musique_courant, 
          mettre_a_jour_messages, 
          definir_programmations_du_jour, 
          programmations_du_jour, 
          definir_musique_courant, 
          definir_playlist_courant,
          ajouter_message_non_lu,
          definir_messages_non_lu,
          definir_conversations,
          definir_toast_message,
          tous_les_messages,
          definir_messages} = useContext(AppContext);

  const {etat_toast, fermer_toast, toast, get_token} = useContext(AppContext);
  let playerButtonRef = useRef<HTMLAnchorElement>(null);
  const history = useHistory();
  const location = useLocation();
  const intervalProgrammation = useRef<any>(null);
  const intervalStat =useRef<any>(null);

  useIonViewDidEnter(() => {
    console.log("ionViewDidEnter event fired in app");
})

  useEffect(() => {
    axios.interceptors.request.use(async function (req: any) {
      let token = await get_token();
      if (token) {
          req.headers.Authorization = `Bearer ${token}`;
      }
      return req
    }, (error) => {
      return Promise.reject(error);
    })

    CapacitorApp.addListener('appStateChange',(state) => {
    //   LocalNotifications.schedule({
    //     notifications: [{
    //         id:1,
    //         title: `AppState : ${state.isActive}`,
    //         body: "",
    //         sound:""
    //     }]
    // })
    })
  }, [get_token]);

  const voir_message = (values: any) => {
    conversationService.voir_tous_les_message_non_lu(values)
        .then(response => {
            if (moi.level === "e20d2fe3d0db14b745b1") {
                definir_conversations(response.data);
                definir_messages_non_lu(response.tous_les_message_non_lu)
            } else if (moi.level === "19631de8f0500b7bda26") {
                let conversation_admin  = response.data.find((conversation: any) => conversation.id === parametre.admin_message_id);
                conversation_admin.lastname="ADMINISTRATEUR";
                conversation_admin.firstname="";
                definir_conversations([conversation_admin]);

                let admin_messages_non_lu = response.tous_les_message_non_lu.filter((message: any) => message.sender === parametre.admin_message_id);
                definir_messages_non_lu(admin_messages_non_lu);
            }
        })
        .catch(erreur => {
            console.error(erreur);
        })
}

  useEffect(() => {
    if (moi && moi.id) {
      // programmationService.tous_les_programmations_par_id_client_par_jour(moi.id,parseInt(moment().format("d")) - 1)
      //   .then(reponse => {
      //     definir_programmations_du_jour(reponse.data);
      //   })
      //   .catch(erreur => {
      //     console.error(erreur);
      //   })

        programmationService.tous_les_programmations_webradios_par_id_client_par_jour(moi.id,parseInt(moment().format("d")) - 1)
        .then(reponse => {
          definir_programmations_du_jour(reponse.data);
        })
        .catch(erreur => {
          console.error(erreur);
        })

      messageService.tous_les_messages_non_lu_par_client(moi.id)
        .then(reponse => {
          definir_messages_non_lu(reponse.data)
        })
        .catch(erreur => {
          console.error(erreur);
        })

      const socket = io(`${config.API_URL}/user-${moi.id}`);
      socket.on("connect", () => {
        // console.log("connected : ",socket.id);
      });
      socket.on("new-message",(args) => {

        console.log("new message", args);

        definir_toast_message(200, "1 Nouveau message")

      if (moi.level === "e20d2fe3d0db14b745b1") {
        definir_conversations(args.conversations)

      } else if (moi.level === "19631de8f0500b7bda26") {
        let conversation_admin  = args.conversations.find((conversation: any) => conversation.id === parametre.admin_message_id);
        conversation_admin.lastname="ADMINISTRATEUR";
        conversation_admin.firstname="";
        definir_conversations([conversation_admin]);
      }

        ajouter_message_non_lu(args.data);
        mettre_a_jour_messages([args.data]);

        if (history.location.pathname == "/message") {
          if (tous_les_messages && tous_les_messages.length) {
            if (tous_les_messages[0].to === args.data.to && tous_les_messages[0].sender == args.data.sender) {
                voir_message({sender: args.data.sender, to: moi.id})
            } else if (tous_les_messages[0].to === args.data.sender && tous_les_messages[0].sender === args.data.to ) {
                voir_message({sender: args.data.sender, to: moi.id})
            }
          }
        }
      })
    }
  },[moi,tous_les_messages, parametre])

  const audioRef = useRef<HTMLAudioElement>(null);
    useEffect(() => {
        if (musique_courant && audioRef && audioRef.current) {
            if (!pause) {
                audioRef.current?.play().then(reponse => {
                // definir_duration(audioRef.current?.duration)
                })
                .catch(error => {
                });
            } else {
                audioRef.current?.pause();
            }

            // audioRef.current.addEventListener("timeupdate", (e) => {
            //     definir_temps_courant(audioRef.current?.currentTime)
            // })

            // audioRef.current.addEventListener("ended",(e) => {
            //     mettre_musique_suivant()
            // })

        }
    },[musique_courant, pause, duration,audioRef]);

  useEffect(() => {
    if (si_authentique && moi && moi.id) {

      // const intervale = setInterval(() => {
      intervalProgrammation.current = setInterval(() => {
        // console.log("programmation intervale");
        let programme_a_lire = [];
        for (let i = 0; i < programmations_du_jour.length; i++) {
          const heure_courant = moment();
          
          const heure_programme_courant = moment(`${moment().format("DD-MM-YYYY")} ${programmations_du_jour[i].time}`, 'DD-MM-YYYY HH:mm:ss');
          
          
          if (heure_programme_courant.isBetween(moment().subtract("seconds",60), heure_courant)) {
            programme_a_lire.push(programmations_du_jour[i]);
          }
        }

        if (programme_a_lire && programme_a_lire.length) {
          // console.log("programme a lire : ", programme_a_lire)

          definir_pause(false);
          definir_musique_courant(
            {
              id: programme_a_lire[0].Webradio.id,
              title: programme_a_lire[0].Webradio.name,
              filename: programme_a_lire[0].Webradio.name,
              created_at: programme_a_lire[0].Webradio.created_at,
              updated_at: programme_a_lire[0].Webradio.updated_at,
              categorie: programme_a_lire[0].Webradio.categorie,
              playlist_id: programme_a_lire[0].Webradio.id,
              link: programme_a_lire[0].Webradio.link,
              is_favoris: programme_a_lire[0].Webradio.is_favoris,
              reading: 1,
              duration: "--"
          }, 0);

          definir_playlist_courant([
            {
              id: programme_a_lire[0].Webradio.id,
              title: programme_a_lire[0].Webradio.name,
              filename: programme_a_lire[0].Webradio.name,
              created_at: programme_a_lire[0].Webradio.created_at,
              updated_at: programme_a_lire[0].Webradio.updated_at,
              categorie: programme_a_lire[0].Webradio.categorie,
              playlist_id: programme_a_lire[0].Webradio.id,
              link: programme_a_lire[0].Webradio.link,
              is_favoris: programme_a_lire[0].Webradio.is_favoris,
              reading: 1,
              duration: "--"
          }
          ]);


          // playlistService.obtenir_un_playlist_par_id_client(programme_a_lire[0].playlist_id, moi.id)
          //   .then(reponse => {
          //     definir_pause(false);
          //     definir_musique_courant(reponse.data.Sounds[0]);
          //     definir_playlist_courant([...reponse.data.Sounds]);
          //   })
          //   .catch(erreur => {
          //     console.error(erreur)
          //   })
        }

      },5000)


      
      // return () => clearInterval(intervale)

      
    }
    return () => clearInterval(intervalProgrammation.current);
    
  }, [programmations_du_jour, si_authentique, moi, playerButtonRef])


  useEffect(() => {
    if (!pause && musique_courant) {
      // const intervale = setInterval(() => {
        intervalStat.current = setInterval(() => {
        // console.log("intervale stat");
        // console.log(musique_courant);
        webradioService.mis_a_jour_stat({id: moi.id,webradio_id: musique_courant.id, meta_link: musique_courant.meta_link}).catch(error => (""));
      }, 15000);
      
      // return () => clearInterval(intervale)
    }
    return () => clearInterval(intervalStat.current);
  },[pause,moi, musique_courant])

  const slugify = (mot: string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìıİłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return mot.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '') // Replace & with 'and'
      // .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
}

  const clearIntervalProgrammation = () => {
    if (intervalProgrammation) {
      clearInterval(intervalProgrammation.current);
      intervalProgrammation.current = null;
    }
  }

  const clearIntervalStat = () => {
    if (intervalStat) {
      clearInterval(intervalStat.current);
      intervalStat.current = null;
    }
  }

  
  return (<>
                  <div hidden>
                      {musique_courant && musique_courant.title ? 
                      (
                        config.ENV == "PROD" ?
                        <audio preload="auto" ref={audioRef} src={musique_courant.categorie != "WEBRADIO" ? `https://www.radiochanteclair.com/public/uploads/sounds/${slugify(musique_courant.Playlist.name)}/${musique_courant.filename}` : musique_courant.link}/>
                        :
                        <audio preload="auto" ref={audioRef} src={musique_courant.categorie != "WEBRADIO" ? `${config.API_URL}/API/musique/play/${musique_courant.filename}` : musique_courant.link}/>
                      )
                      : null}
                  </div>
                  <IonToast isOpen={etat_toast} message={toast?.message} onDidDismiss={() => fermer_toast()} position="top" duration={3000}/>
                  <IonPage>
                      <HeaderWithContent clearIntervalStat={() => clearIntervalStat()} clearIntervalProgrammation={() => clearIntervalProgrammation()}>
                        <IonRouterOutlet id="main">
                          <Route exact path="/">
                            <Redirect to="/accueil"/>
                          </Route>
                          <Route exact path="/connexion">
                            <Connexion/>
                          </Route>
                          <Route exact path="/inscription">
                            <Inscription/>
                          </Route>
                          <Route exact path="/recuperation-mot-de-passe">
                            <Recovery/>
                          </Route>
                          <Route exact path="/changer-mot-de-passe">
                            <MotDePassse/>
                          </Route>
                          <Route exact path="/musiques">
                            <Musics/>
                          </Route>
                          <HiddenRoute exact path="/accueil">
                            <Accueil/>
                          </HiddenRoute>
                          <Route exact path="/playlists">
                            <Playlist clearIntervalStat={() => clearIntervalStat()}/>
                          </Route>
                          <Route exact path="/player">
                            <Player/>
                          </Route>
                          <Route exact path="/activation-compte">
                            <ActivationCompte/>
                          </Route>
                          <PrivateRoute exact path="/message">
                            <Message/>
                          </PrivateRoute>
                          <PrivateRoute exact path="/conversations">
                            <Conversation/>
                          </PrivateRoute>
                          <PrivateRoute exact path="/coordonnées">
                            <Coordonnees clearIntervalStat={() => clearIntervalStat()} clearIntervalProgrammation={() => clearIntervalProgrammation()}/>
                          </PrivateRoute>
                          <PrivateRoute exact path="/favoris">
                            <Favoris/>
                          </PrivateRoute>
                          <PrivateRoute exact path="/programmations">
                            <Programmation/>
                          </PrivateRoute>
                          <Route exact path="/activation-succes">
                            <SuccesActivation/>
                          </Route>
                          <Route exact path="/activation-erreur">
                            <ErreurActivation/>
                          </Route>
                        </IonRouterOutlet>
                          </HeaderWithContent>
                  </IonPage>
                  </> 
                
)};

export default App;
