import axios from 'axios';
import config from '../../config/config.json';


class ProgrammationService {
    async tous_les_programmations_par_id_client_par_jour(clientId: number, jour: number) {
        const {data} = await axios.get(`${config.API_URL}/API/programmation/tout/${clientId}/jour/${jour}`);
        return data;
    }

    async tous_les_programmations_webradios_par_id_client_par_jour(clientId: number, jour: number) {
        const {data} = await axios.get(`${config.API_URL}/API/programmation-webradio/tout/${clientId}/jour/${jour}`);
        return data;
    }

    async tous_les_programmations_par_id_client(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/programmation/tout/${clientId}`);
        return data;
    }

    async tous_les_programmations_webradios_par_id_client(clientId: number) {
        const {data} = await axios.get(`${config.API_URL}/API/programmation-webradio/tout/${clientId}`);
        return data;
    }

    async ajouter_programme(donnees: object) {
        const {data} = await axios.post(`${config.API_URL}/API/programmation/ajout`, donnees);
        return data;
    }

    async ajouter_programme_webradio(donnees: object) {
        const {data} = await axios.post(`${config.API_URL}/API/programmation-webradio/ajout`, donnees);
        return data;
    }

    async modifier_programme(programmeId: number, donnees: object) {
        const {data} = await axios.put(`${config.API_URL}/API/programmation/modification/${programmeId}`, donnees);
        return data;
    }

    async supprimer_programme(programmeId: number, user_id: number, playlist_id: number, jour: number) {
        const {data} = await axios.delete(`${config.API_URL}/API/programmation/suppression/${programmeId}/user/${user_id}/playlist/${playlist_id}/jour/${jour}`);
        return data;
    }

    async supprimer_programme_webradio(programmeId: number, user_id: number, playlist_id: number, jour: number) {
        const {data} = await axios.delete(`${config.API_URL}/API/programmation-webradio/suppression/${programmeId}/user/${user_id}/webradio/${playlist_id}/jour/${jour}`);
        return data;
    }
}

export default new ProgrammationService();