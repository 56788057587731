import React, { useContext, useState } from "react";
import { IonPage, IonContent } from "@ionic/react";
import { Formik } from "formik";
import * as Yup from "yup";
import inscriptionService from "./inscriptionService";
import { AppContext } from "../appProvider";
import HeaderWithContent from "../../components/headerWithContent";
import { useHistory, Redirect } from "react-router-dom";
import { LocalNotifications } from "@capacitor/local-notifications";

const Inscription: React.FC = () => {
    const history = useHistory();
    const { definir_toast_message, si_authentique } = useContext(AppContext);
    const [didFocus, setDidFocus] = useState(false);
    const [currentField, setCurrentField] = useState("");
    const [loading_inscription, set_loading_inscription] = useState(false);

    if (si_authentique) {
        return <Redirect to="/playlists" />;
    }

    const handleDidFocus = (field: string) => {
        setDidFocus(true);
        setCurrentField(field);
    };

    return (
        <IonContent>
            {/* <IonPage>
       <HeaderWithContent> */}
            <Formik
                initialValues={{
                    company: "",
                    // username: "",
                    firstname: "",
                    lastname: "",
                    // firstname_lastname: "",
                    city: "",
                    address: "",
                    postal_code: "",
                    siret: "",
                    crypte: "",
                    email: "",
                    phone: "",
                    password: "",
                    confirmation: "",
                    contrat_sacem: "",
                }}
                validationSchema={Yup.object({
                    company: Yup.string().required(
                        "Nom de l'etablissement requis"
                    ),
                    // username: Yup.string()
                    //     .max(250, "doit etre inferieur a 255 caractères")
                    //     .required("Nom d'utilisateur requis"),
                    firstname: Yup.string()
                        .max(250, "doit etre inferieur a 255 caractères")
                        .required("Prenom requis"),
                    lastname: Yup.string()
                        .max(250, "doit etre inferieur a 255 caractères")
                        .required("Nom requis"),
                    // firstname_lastname: Yup.string()
                    //     .max(250, "doit etre inferieur a 255 caractères")
                    //     .required("Nom et prénom requis"),
                    city: Yup.string().required("Ville requis"),
                    address: Yup.string().required("Adresse requis"),
                    postal_code: Yup.string().required("code postale requis"),
                    siret: Yup.string()
                        .matches(
                            /^[0-9]{14}$/,
                            "doit etre une serie de 14 chiffres"
                        )
                        .required("Siret requis"),
                    email: Yup.string()
                        .email("Doit etre un email valide")
                        .required("Email requis"),
                    phone: Yup.string().matches(/^(\+)?([0-9]){1,4}([0-9]){6,9}$/,"Doit etre un telephone valide").required("Telephone requis"),
                    password: Yup.string().required("Mot de passe requis"),
                    confirmation: Yup.string()
                        .required("Confirmation requis")
                        .oneOf(
                            [Yup.ref("password"), null],
                            "Le mote de passe doit correspondre"
                        ),
                    contrat_sacem: Yup.bool().oneOf([true], "Vous devez disposer d'un contrat SACEM").required("Vous devez disposer d'un contrat SACEM"),
                })}
                onSubmit={(values: any) => {
                    set_loading_inscription(true);
                    inscriptionService
                        .ajoutClient(values)
                        .then((reponse) => {
                            definir_toast_message(
                                reponse.status,
                                reponse.message
                            );
                            set_loading_inscription(false);
                            history.push("/connexion");
                            LocalNotifications.schedule({
                                notifications: [
                                    {
                                        id: 1,
                                        title: `Radio Chanteclair`,
                                        body: `${reponse.message}`,
                                        sound: "",
                                    },
                                ],
                            });
                        })
                        .catch((erreur) => {
                            if (erreur && erreur?.response?.data?.status) {
                                definir_toast_message(
                                    erreur.response.data.status,
                                    erreur.response.data.message
                                );
                                LocalNotifications.schedule({
                                    notifications: [
                                        {
                                            id: 1,
                                            title: `Radio Chanteclair`,
                                            body: `${erreur.response.data.message}`,
                                            sound: "",
                                        },
                                    ],
                                });
                            } else {
                                if (erreur.code == "ERR_NETWORK") {
                                    definir_toast_message(
                                        400,
                                        "Verifier votre connexion internet"
                                    );
                                    LocalNotifications.schedule({
                                        notifications: [
                                            {
                                                id: 1,
                                                title: `Radio Chanteclair`,
                                                body: "Verifier votre connexion internet",
                                                sound: "",
                                            },
                                        ],
                                    });
                                } else {
                                    definir_toast_message(400, erreur.message);
                                }
                            }
                            set_loading_inscription(false);
                        });
                }}
            >
                {(formik) => (
                    <div>
                        <form
                            className="p-10 space-y-5"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="space-y-3">
                                <label className="block" htmlFor="company">
                                    Nom de l'établissement
                                </label>
                                <input
                                    type="text"
                                    id="company"
                                    {...formik.getFieldProps("company")}
                                    onFocus={() => handleDidFocus("company")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "nom" &&
                                            formik.errors.company) ||
                                        (formik.touched.company &&
                                            formik.errors.company)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "company" &&
                                    formik.errors.company) ||
                                (formik.touched.company &&
                                    formik.errors.company) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.company}
                                    </span>
                                ) : null}
                            </div>
                            {/* <div className="space-y-3">
                                <label className="block" htmlFor="username">
                                    Nom d'utilisateur
                                </label>
                                <input
                                    type="text"
                                    id="username"
                                    {...formik.getFieldProps("username")}
                                    onFocus={() => handleDidFocus("username")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "username" &&
                                            formik.errors.username) ||
                                        (formik.touched.username &&
                                            formik.errors.username)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "username" &&
                                    formik.errors.username) ||
                                (formik.touched.username &&
                                    formik.errors.username) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.username}
                                    </span>
                                ) : null}
                            </div> */}
                            <div className="space-y-3">
                                <label className="block" htmlFor="lastname">
                                    Nom
                                </label>
                                <input
                                    type="text"
                                    id="lastname"
                                    {...formik.getFieldProps("lastname")}
                                    onFocus={() => handleDidFocus("lastname")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "lastname" &&
                                            formik.errors.lastname) ||
                                        (formik.touched.lastname &&
                                            formik.errors.lastname)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "lastname" &&
                                    formik.errors.lastname) ||
                                (formik.touched.lastname &&
                                    formik.errors.lastname) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.lastname}
                                    </span>
                                ) : null}
                            </div>
                            <div className="space-y-3">
                                <label className="block" htmlFor="firstname">
                                    Prénom
                                </label>
                                <input
                                    type="text"
                                    id="firstname"
                                    {...formik.getFieldProps("firstname")}
                                    onFocus={() => handleDidFocus("firstname")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "firstname" &&
                                            formik.errors.firstname) ||
                                        (formik.touched.firstname &&
                                            formik.errors.firstname)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "firstname" &&
                                    formik.errors.firstname) ||
                                (formik.touched.firstname &&
                                    formik.errors.firstname) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.firstname}
                                    </span>
                                ) : null}
                            </div>
                            {/* <div className="space-y-3">
                                <label
                                    className="block"
                                    htmlFor="firstname_lastname"
                                >
                                    Nom et Prénom
                                </label>
                                <input
                                    type="text"
                                    id="firstname_lastname"
                                    {...formik.getFieldProps(
                                        "firstname_lastname"
                                    )}
                                    onFocus={() =>
                                        handleDidFocus("firstname_lastname")
                                    }
                                    className={`${
                                        (!!didFocus &&
                                            currentField ==
                                                "firstname_lastname" &&
                                            formik.errors.firstname_lastname) ||
                                        (formik.touched.firstname_lastname &&
                                            formik.errors.firstname_lastname)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "firstname_lastname" &&
                                    formik.errors.firstname_lastname) ||
                                (formik.touched.firstname_lastname &&
                                    formik.errors.firstname_lastname) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.firstname_lastname}
                                    </span>
                                ) : null}
                            </div> */}
                            <div className="space-y-3">
                                <label className="block" htmlFor="address">
                                    Adresse
                                </label>
                                <input
                                    type="text"
                                    id="address"
                                    {...formik.getFieldProps("address")}
                                    onFocus={() => handleDidFocus("address")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "address" &&
                                            formik.errors.address) ||
                                        (formik.touched.address &&
                                            formik.errors.address)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "address" &&
                                    formik.errors.address) ||
                                (formik.touched.address &&
                                    formik.errors.address) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.address}
                                    </span>
                                ) : null}
                            </div>
                            <div className="space-y-3">
                                <label
                                    className="postal_code"
                                    htmlFor="postal_code"
                                >
                                    Code postal
                                </label>
                                <input
                                    type="text"
                                    id="postal_code"
                                    {...formik.getFieldProps("postal_code")}
                                    onFocus={() =>
                                        handleDidFocus("postal_code")
                                    }
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "postal_code" &&
                                            formik.errors.postal_code) ||
                                        (formik.touched.postal_code &&
                                            formik.errors.postal_code)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "postal_code" &&
                                    formik.errors.postal_code) ||
                                (formik.touched.postal_code &&
                                    formik.errors.postal_code) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.postal_code}
                                    </span>
                                ) : null}
                            </div>
                            <div className="space-y-3">
                                <label className="block" htmlFor="city">
                                    Ville
                                </label>
                                <input
                                    type="text"
                                    id="city"
                                    {...formik.getFieldProps("city")}
                                    onFocus={() => handleDidFocus("city")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "city" &&
                                            formik.errors.city) ||
                                        (formik.touched.city &&
                                            formik.errors.city)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "city" &&
                                    formik.errors.city) ||
                                (formik.touched.city && formik.errors.city) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.city}
                                    </span>
                                ) : null}
                            </div>
                            <div className="space-y-3">
                                <label className="block" htmlFor="phone">
                                    Téléphone
                                </label>
                                <input
                                    type="text"
                                    id="phone"
                                    {...formik.getFieldProps("phone")}
                                    onFocus={() => handleDidFocus("phone")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "phone" &&
                                            formik.errors.phone) ||
                                        (formik.touched.phone &&
                                            formik.errors.phone)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "phone" &&
                                    formik.errors.phone) ||
                                (formik.touched.phone &&
                                    formik.errors.phone) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.phone}
                                    </span>
                                ) : null}
                            </div>
                            <div className="space-y-3">
                                <label className="block" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    {...formik.getFieldProps("email")}
                                    onFocus={() => handleDidFocus("email")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "email" &&
                                            formik.errors.email) ||
                                        (formik.touched.email &&
                                            formik.errors.email)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "email" &&
                                    formik.errors.email) ||
                                (formik.touched.email &&
                                    formik.errors.email) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.email}
                                    </span>
                                ) : null}
                            </div>
                            <div className="space-y-3">
                                <label className="block">
                                    Siret
                                </label>
                                <input
                                    type="text"
                                    id="siret"
                                    {...formik.getFieldProps("siret")}
                                    onFocus={() => handleDidFocus("siret")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "siret" &&
                                            formik.errors.siret) ||
                                        (formik.touched.siret &&
                                            formik.errors.siret)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "siret" &&
                                    formik.errors.siret) ||
                                (formik.touched.siret &&
                                    formik.errors.siret) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.siret}
                                    </span>
                                ) : null}
                            </div>

                            <div className="space-y-3 w-full">
                                <label className="block" htmlFor="password">
                                    Mot de passe
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    {...formik.getFieldProps("password")}
                                    onFocus={() => handleDidFocus("password")}
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "password" &&
                                            formik.errors.password) ||
                                        (formik.touched.password &&
                                            formik.errors.password)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "password" &&
                                    formik.errors.password) ||
                                (formik.touched.password &&
                                    formik.errors.password) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.password}
                                    </span>
                                ) : null}
                            </div>
                            <div className="space-y-3 w-full">
                                <label className="block">Confirmation</label>
                                <input
                                    type="password"
                                    id="confirmation"
                                    {...formik.getFieldProps("confirmation")}
                                    onFocus={() =>
                                        handleDidFocus("confirmation")
                                    }
                                    className={`${
                                        (!!didFocus &&
                                            currentField == "confirmation" &&
                                            formik.errors.confirmation) ||
                                        (formik.touched.confirmation &&
                                            formik.errors.confirmation)
                                            ? "border border-red-500 focus:ring-2 focus:ring-red-500"
                                            : "border border-gray-500 focus:ring-2 focus:ring-blue-500"
                                    } w-full block mt-3 px-3 py-2  rounded outline-none  placeholder:italic placeholcer:text-gray-400`}
                                />
                                {(!!didFocus &&
                                    currentField == "confirmation" &&
                                    formik.errors.confirmation) ||
                                (formik.touched.confirmation &&
                                    formik.errors.confirmation) ? (
                                    <span className="text-red-500 text-sm">
                                        {formik.errors.confirmation}
                                    </span>
                                ) : null}
                            </div>

                            <div>
                                <label htmlFor="contrat_sacem">
                                    <input
                                        type="checkbox"
                                        {...formik.getFieldProps(
                                            "contrat_sacem"
                                        )}
                                    />{" "}
                                    Je reconnais disposer d’un contrat SACEM me
                                    permettant de diffuser les œuvres de
                                    Chanteclair.
                                </label>
                                {formik.errors.contrat_sacem ? (
                                    <span className="block text-red-500 text-sm">
                                        {formik.errors.contrat_sacem}
                                    </span>
                                ) : null}
                            </div>
                            <div className="">
                                {loading_inscription ? (
                                    <button
                                        disabled
                                        type="button"
                                        className="block space-x-2 mx-auto bg-orange-300 rounded text-white px-4 py-2 w-full"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            className="animate-spin inline-block w-6 h-6"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                            />
                                        </svg>
                                        &nbsp;&nbsp;inscription en cours
                                    </button>
                                ) : (
                                    <button
                                        className="block mx-auto bg-[#EA491D] rounded text-white px-4 py-2 w-full"
                                        type="submit"
                                    >
                                        Je m'inscris
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                )}
            </Formik>
            {/* </HeaderWithContent>
    </IonPage> */}
        </IonContent>
    );
};

export default Inscription;
